<template>
  <span>
    <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1200"
        :filename="fileName"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
    >
      <section slot="pdf-content" style="margin: 50px 35px">
        <v-row>
          <v-hover v-slot="" close-delay="170">
            <v-col cols="12" class="">
              <div>
                <h2>
                  {{ timePlan.name }}
                </h2>
                <h3>
                  <v-icon class="pe-2">mdi-calendar</v-icon>
                  {{
                    new Date(timePlan.date).getDate() + "." + (new Date(timePlan.date).getMonth() + 1) + "." + new Date(timePlan.date).getFullYear()
                  }}
                </h3>
              </div>
            </v-col>
          </v-hover>
        </v-row>
        <v-row class="">
          <v-col>
            <h2 class="subtitle-1">
              {{ timePlan.description }}
            </h2>
          </v-col>
        </v-row>

        <v-row class="pa-2">
          <ul class="mt-3">
              <li v-for="item of searchedEntries"
                  :key="item.id">
                <v-container>
                  <v-row>
                    <v-col cols="12" lg="3">
                      <div class=" ">
                        <h3><b>{{ item.start }} <span
                            v-if="item.end">- {{ item.end }}</span></b></h3>
                      </div>
                    </v-col>
                    <v-col cols="12" lg="5">
                      <div class=" ">
                        <div>
                          <h4 class="mb-0 pb-0"> {{ item.name }}</h4>
                          <h5 class=" grey--text mt-0">{{ item.description }}</h5>
                        </div>
                       </div>
                    </v-col>

                    <v-col cols="12" lg="4">
                      <div>
                        <div v-for="(vendor, index) of item.vendors" :key="index">
                          <v-icon class="primary--text pe-2" @click="openVendor(vendor.id)">mdi-domain</v-icon>
                          <span>{{ vendor.name }}</span>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </li>

          </ul>

        </v-row>
      </section>
    </vue-html2pdf>
    <a @click="generateReport" class="d-inline-flex mt-5 ps-3 pe-3">
      <v-icon class="d-inline-flex" size="20">
        mdi mdi-download
      </v-icon>
    </a>
  </span>
</template>
<script>
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
import VueHtml2pdf from 'vue-html2pdf'
import {dotToKomma, error} from "@/helper/helper";
//const Doughnut = () => import("@/components/generalUI/charts/Doughnut");

export default {
  name: "GeneratePdf",
  components: {
    VueHtml2pdf,
    // Doughnut
  },
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    /**
     * Contains data to display the alert accordingly
     */
    fileName: {
      type: String,
      default() {
        return ''
      }
    },
    timePlan: {
      type: Object,
      default() {
        return {}
      }
    },
    timePlanEntries: {
      type: Object,
      default() {
        return {}
      }
    },
    searchedEntries: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  methods: {
    /*
        Generate Report using refs and calling the
        refs function generatePdf()
    */
    /*
        Generate Report using refs and calling the
        refs function generatePdf()
    */
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    replaceDot(float) {
      return dotToKomma(float)
    },
    isZero(number) {
      if (number < 0) {
        return 0
      } else {
        return number
      }
    },
    getSumPlanned(entries) {
      let sum = 0.00
      for (let entry of entries) {
        sum = sum + entry.planned
      }
      return sum.toFixed(2)
    },
    getSumSpent(category, entries) {
      let sum = 0.00
      for (let entry of entries) {
        sum = sum + entry.spent
      }
      this.categorySums[category] = sum
      return sum.toFixed(2)
    },
    getTotalSumPlanned(categories) {
      let sum = 0.00
      for (let category of categories) {
        for (let singeBudget of category.singleBudget) {
          sum = sum + singeBudget.planned
        }
      }
      return sum.toFixed(2)
    },
    getTotalSumSpent(categories) {
      let sum = 0.00
      for (let category of categories) {
        for (let singeBudget of category.singleBudget) {
          sum = sum + singeBudget.spent
        }
      }
      return sum.toFixed(2)
    },
    changeName(name) {
      this.$store.dispatch('customer/editField', {
        entity: "budgetPlan",
        uid: this.user.id,
        id: this.$route.params.id,
        newValue: name,
        row: 'name'
      }).then(() => {
        this.budgetPlan.name = name
      }).catch((err) => {
        this.message = error(err)
      })
    },
  },


}
</script>


